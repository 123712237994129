:root {
  --main-font: "Montserrat", sans-serif;
  --reey-font: "reeyregular";
  --base-purple: #2a0e59;
  --primary: #ffd45f;
  --gray: #757783;
  --link-gray: #aeb2c2;
  --light-gray: #626368;
  --theme-black: #1c1e27;
  --theme-black-rgb: 28, 30, 39;
  --brand-yellow: #ffba1b;
  --brand-blue: #0b44b3;
  --red: #fc0200;
  --blue: #28abfd;
  --body-side-paddings: 11rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.mt-05 {
  margin-top: 0.5rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.navbar {
  padding: 1rem 5rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.validation-message {
  color: var(--red);
  font-size: 0.8rem;
  display: inline-block;
  margin: 0.5rem;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}

.loading-overlay.overlay-active {
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color--blue {
  color: var(--brand-blue);
  font-weight: 500;
}

.form-subjects-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.subject-name {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.subject-name > p {
  margin-left: 0.5rem;
}

@media screen and (max-width: 500px) {
  .navbar {
    padding: 1rem 2rem;
  }

  .subject-name > p {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
}
