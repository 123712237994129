.home {
  width: 100%;
  height: initial;
  background-color: #fff;
  color: #000;
  padding-bottom: 2rem;
}

.home__heading {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.home__form-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.home__form {
  width: 40%;
  padding: 3rem;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
}

.home__categories {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.home__categories button {
  width: 50%;
  border-radius: 0;
}

.tc {
  display: inline-block;
  margin: 1rem 0;
  font-size: 0.68rem;
}

.tc-input {
  display: flex;
  align-items: center;
}

.thankyou {
  height: 85vh;
  background-color: #fff;
  color: #000;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.thankyou__message {
  font-size: 2rem;
  font-weight: 500;
  margin-top: -10rem;
}

.thankyou__info {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .home{
    padding: 1rem;
    padding-top: 2rem;
  }
  
  .home__form-container {
    width: 100%;
  }

  .home__heading {
    text-align: center;
    font-size: 1.1rem;
  }

  .home__form {
    width: 100%;
    padding: 1rem;
  }
  .thankyou__message {
    font-size: 1.3rem;
  }

  .thankyou__info {
    font-size: 1rem;
  }
}
